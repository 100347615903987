<template>
  <el-container class="container">
    <Header></Header>
    <el-container>
      <Menu></Menu>
      <el-main class="main">
        <Breadcrumb></Breadcrumb>
        <div class="content">
          <div class="amend-content">
            <div class="amend-title">修改密码</div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="amend-form"
            >
              <el-form-item label="旧密码" prop="oldPassword">
                <el-input
                  v-model="ruleForm.oldPassword"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="newPassword">
                <el-input
                  v-model="ruleForm.newPassword"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="passwordConfirmation">
                <el-input
                  v-model="ruleForm.passwordConfirmation"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >修改密码</el-button
                >
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/header/header.vue";
import Menu from "@/components/menu/menu.vue";
import Breadcrumb from "@/components/breadcrumb/breadcrumb.vue";
import { resetPassword } from "@/api/apiList/admin-api";

export default {
  name: "Home",
  components: {
    Header,
    Menu,
    Breadcrumb,
  },
  data() {
    return {
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        passwordConfirmation: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        passwordConfirmation: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          resetPassword(this.ruleForm).then((res) => {
            if (res.result === "200") {
              localStorage.setItem("token", res.data.token);
              this.$message.success({
                message: "密码修改成功",
                type: "success",
              });
              this.$store.commit("changeTagArr", []);
              this.$router.push("/");
            } else if (res.result === "200003") {
              this.$message.error("旧密码输入错误");
            } else if (res.result === "200004") {
              this.$message.error("两次密码输入不一致");
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss">
.amend-content {
  width: 500px;
  margin: 0 auto;
  margin-top: 100px;

  .amend-title {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    text-align: center;
  }

  .amend-form {
    margin-top: 30px;
  }
}
</style>
